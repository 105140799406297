import { useFormik } from 'formik';
import { Button, Text, GridFlex, ExternalLink, Box, DetailsLayout, drawFormFields, FadeBox } from '@stigg-components';
import { useConfirmationDialog } from '@stigg-common';
import { useAccountPermissionCheck } from '@stigg-permissions';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as Yup from 'yup';
import { AccountAccessRole, EnvironmentAccessRole } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { PageCard } from '../../../components/PageCard';
import { RootState, useAppDispatch } from '../../../redux/store';
import { setRolesFields } from './setRolesFields';
import { updateAccountAction } from '../accountsSlice';
import { AccountPermissionActions } from '../../rbac';
import { TooltipFields } from '../../../components/InformationTooltip';

const formatContactSupportLink = () => {
  const subject = encodeURIComponent(`Hey! i'd like to add a SSO integration`);
  return `mailto:support@stigg.io?subject=${subject}`;
};
export const NotConfiguredStyledBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  background-color: ${({ theme }) => theme.itamar.palette.background.emptyState};
`;

type SSOSettingsFormFields = {
  accountRole: AccountAccessRole;
  productionAccess: EnvironmentAccessRole;
  nonProductionAccess: EnvironmentAccessRole;
};

const DEFAULT_SSO_ROLES = {
  accountRole: AccountAccessRole.Member,
  nonProductionAccess: EnvironmentAccessRole.Viewer,
  productionAccess: EnvironmentAccessRole.Viewer,
};

const validationSchema = () =>
  Yup.object().shape({
    accountRole: Yup.mixed<AccountAccessRole>()
      .oneOf(Object.values(AccountAccessRole))
      .required(t('fieldValidationMessages.required')),
    productionAccess: Yup.mixed<EnvironmentAccessRole>()
      .oneOf(Object.values(EnvironmentAccessRole))
      .required(t('fieldValidationMessages.required')),
    nonProductionAccess: Yup.mixed<EnvironmentAccessRole>()
      .oneOf(Object.values(EnvironmentAccessRole))
      .required(t('fieldValidationMessages.required')),
  });

function SingleSignOnSettings() {
  const { rbacRollout } = useFlags<FeatureFlags>();
  const dispatch = useAppDispatch();
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const allowChangeAccessLevel = useAccountPermissionCheck(AccountPermissionActions.InviteUser);

  const initialValues: SSOSettingsFormFields = account?.defaultSSORoles
    ? {
        accountRole: account.defaultSSORoles.accountRole,
        nonProductionAccess: account.defaultSSORoles.nonProductionRole,
        productionAccess: account.defaultSSORoles.productionRole,
      }
    : DEFAULT_SSO_ROLES;

  const onSubmit = async (values: SSOSettingsFormFields) => {
    if (!account) {
      return;
    }
    await dispatch(
      updateAccountAction({
        displayName: account.displayName,
        defaultSSORoles: {
          accountRole: values.accountRole,
          nonProductionRole: values.nonProductionAccess,
          productionRole: values.productionAccess,
        },
      }),
    );
  };

  const disableTooltip: TooltipFields | undefined = {
    placement: 'top',
    title: <Text.B2 color="primary">{t('permissions.changeSSODefaultAccessLevelDenied')}</Text.B2>,
  };

  const formRenderProps = useFormik<SSOSettingsFormFields>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { dirty, resetForm, isValid, submitForm } = formRenderProps;
  const fields = setRolesFields<SSOSettingsFormFields>({
    hide: !rbacRollout,
    withLabels: false,
    accountRoleDisabled: !allowChangeAccessLevel,
    environmentRoleDisabled: !allowChangeAccessLevel,
    accountTooltip: !allowChangeAccessLevel ? disableTooltip : undefined,
    environmentTooltip: !allowChangeAccessLevel ? disableTooltip : undefined,
  });

  const [ConfirmDialog, showConfirmDialog, confirmDialogProps] = useConfirmationDialog({
    title: t('accounts.sso.confirmationTitle'),
    width: 750,
    content: t('accounts.sso.confirmationContent'),
    confirmButtonColor: 'primary',
    confirmButtonText: t('sharedComponents.saveChanges'),
    handleConfirm: submitForm,
  });

  return (
    <PageCard>
      <GridFlex.Column $fullWidth>
        <GridFlex.Row alignItems="start" $fullWidth justifyContent="space-between">
          <GridFlex.Column>
            <Text.H3 mb={2}>{t('accounts.sso.title')}</Text.H3>
            <GridFlex.Row>
              <Text.Sub2 color="secondary" mr={1}>
                {t('accounts.sso.subtitle')}
                &nbsp;
                <ExternalLink label={t('accounts.sso.learnMore')} url="https://docs.stigg.io/docs/single-sign-on" />
              </Text.Sub2>
            </GridFlex.Row>
          </GridFlex.Column>

          <FadeBox className={!dirty ? 'hidden' : ''}>
            <GridFlex.Row>
              <Button onClick={() => resetForm()} sx={{ mr: 3 }} $outlined color="primary">
                {t('sharedComponents.cancel')}
              </Button>
              <Button variant="contained" color="primary" disabled={!isValid} onClick={() => showConfirmDialog(true)}>
                {t('sharedComponents.saveChanges')}
              </Button>
            </GridFlex.Row>
          </FadeBox>
        </GridFlex.Row>

        {account?.samlEnabled ? (
          <GridFlex.Column>
            <Text.B2 $bold my={6}>
              {t('accounts.sso.ssoSectionTitle')}
            </Text.B2>

            <DetailsLayout
              titleWidth={200}
              rowGap={4}
              details={[
                {
                  title: t('accounts.sso.enforcement'),
                  content: t('accounts.sso.enforcementValue'),
                },
                {
                  title: t('accounts.sso.idp'),
                  content: t('accounts.sso.idpValue'),
                },
                {
                  title: t('accounts.sso.authDomain'),
                  content: account?.accountEmailDomain,
                  helperTooltip: <Text.B2>{t('accounts.sso.authDomainTooltip')}</Text.B2>,
                },
              ]}
            />

            {rbacRollout ? (
              <>
                <Text.B2 $bold my={6}>
                  {t('accounts.sso.defaultRolesTitle')}
                </Text.B2>

                <GridFlex.Column rowGap={4} $fullWidth>
                  <GridFlex.RowCenter $fullWidth>
                    <GridFlex.Item sx={{ width: 200, minWidth: 200, mr: 2 }}>
                      <Text.B2 color="secondary">Account role</Text.B2>
                    </GridFlex.Item>
                    <GridFlex.Item sx={{ width: 250 }}>{drawFormFields([fields[0]], formRenderProps)}</GridFlex.Item>
                  </GridFlex.RowCenter>
                  <GridFlex.RowCenter>
                    <GridFlex.Item sx={{ width: 200, minWidth: 200, mr: 2 }}>
                      <Text.B2 color="secondary">Production access</Text.B2>
                    </GridFlex.Item>
                    <GridFlex.Item sx={{ width: 250 }}>{drawFormFields([fields[1]], formRenderProps)}</GridFlex.Item>
                  </GridFlex.RowCenter>
                  <GridFlex.RowCenter>
                    <GridFlex.Item sx={{ width: 200, minWidth: 200, mr: 2 }}>
                      <Text.B2 color="secondary">Non-production access</Text.B2>
                    </GridFlex.Item>
                    <GridFlex.Item sx={{ width: 250 }}>{drawFormFields([fields[2]], formRenderProps)}</GridFlex.Item>
                  </GridFlex.RowCenter>
                </GridFlex.Column>
              </>
            ) : null}
          </GridFlex.Column>
        ) : (
          <NotConfiguredStyledBox mt={2}>
            <Text.B2 mr={1}>{t('accounts.sso.notConfigured')}</Text.B2>
            <ExternalLink label={t('accounts.sso.contactSupport')} url={formatContactSupportLink()} />
          </NotConfiguredStyledBox>
        )}
      </GridFlex.Column>

      <ConfirmDialog {...confirmDialogProps} />
    </PageCard>
  );
}

export default SingleSignOnSettings;
