import { useEffect } from 'react';
import { Grid, Tabs, Text } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAccountPermissionCheck } from '@stigg-permissions';
import Loader from '../../../components/Loader';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchAccountAction, fetchAccountMembersAction } from '../accountsSlice';
import { AccountLayout } from './AccountLayout';
import { BillingCustomerPortal } from './BillingCustomerPortal';
import { SettingsPageTabs } from '../types/settingsPageTabs';
import AccountMembers from './AccountMembers';
import { AccountPermissionActions } from '../../rbac/consts';
import SingleSignOnSettings from './SingleSignOnSettings';

function Settings() {
  const dispatch = useAppDispatch();
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const isLoading = useSelector((state: RootState) => state.accountReducer.isLoading);
  const showBillingTab = useAccountPermissionCheck(AccountPermissionActions.BillingAccess);

  useEffect(() => {
    void dispatch(fetchAccountMembersAction({}));
  }, [dispatch]);

  useEffect(() => {
    void dispatch(fetchAccountAction());
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid wrap="nowrap" container flexDirection="column" rowSpacing={4}>
      <Text.H1 mb={6}>{t('accounts.pageTitle')}</Text.H1>

      <Tabs
        data={[
          {
            title: SettingsPageTabs.Account,
            content: <AccountLayout account={account} />,
          },
          {
            title: SettingsPageTabs.Members,
            content: <AccountMembers />,
          },
          {
            title: SettingsPageTabs.SSO,
            content: <SingleSignOnSettings />,
          },
          {
            title: SettingsPageTabs.Billing,
            content: <BillingCustomerPortal />,
            hidden: !showBillingTab,
          },
        ]}
      />
    </Grid>
  );
}

export default Settings;
