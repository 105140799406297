import { PricingType, Subscription, MeteredEntitlement } from '@stigg/react-sdk';
import { t } from 'i18next';
import { Alert, Icon, Link, Text } from '@stigg-components';
import React from 'react';
import styled from 'styled-components/macro';
import { useNavigation } from '../../../navigation/useNavigation';
import { getFreeContext, getPaidContext } from './MembersLimitNotification.utils';
import { SettingsPageTabs } from '../../types/settingsPageTabs';

export type MembersLimitNotificationProps = {
  entitlement: MeteredEntitlement;
  activeSubscriptions: Subscription[] | null;
};

const IconWrapper = styled.div<{ severityWarning: boolean }>`
  margin-top: 8px;
  path {
    stroke: ${({ theme, severityWarning }) =>
      `${severityWarning ? theme.itamar.palette.warning.main : theme.itamar.palette.primary.main} !important`};
  }
`;

export function MembersLimitNotification({ entitlement, activeSubscriptions }: MembersLimitNotificationProps) {
  const navigation = useNavigation();
  if (!activeSubscriptions || activeSubscriptions.length === 0) {
    return null; /// handle missing subscriptions
  }

  const isCustomSubscription = activeSubscriptions.some((sub) => sub.pricingType === PricingType.Custom);
  if (entitlement.isUnlimited || !entitlement.usageLimit || isCustomSubscription) {
    return null;
  }

  let text;
  let severityWarning = false;
  let limited = false;

  const additionalTeamMembers = entitlement.usageLimit - entitlement.currentUsage;
  const paidSubscription = activeSubscriptions.find((sub) => sub.pricingType === PricingType.Paid);
  if (paidSubscription) {
    text = getPaidContext({ paidSubscription, additionalTeamMembers });
  } else {
    const isFreeSubscription = activeSubscriptions.some((sub) => sub.pricingType === PricingType.Free);
    if (!isFreeSubscription) {
      return null; /// error! pricingType not one of custom, paid or free
    }
    limited = true;
    ({ text, severityWarning } = getFreeContext({ additionalTeamMembers }));
  }

  return (
    <Alert
      icon={
        <IconWrapper severityWarning={severityWarning}>
          <Icon icon="PowerUp" type="custom" size={25} />
        </IconWrapper>
      }
      sx={{ alignItems: 'center', mb: 4, width: '100%' }}
      severity={severityWarning ? 'warning' : 'info'}>
      <Text.B2 color="default">
        {text}{' '}
        {limited && (
          <Link
            onClick={() => {
              navigation.navigateTo(navigation.appRoutes.settingsPage({ selectedTab: SettingsPageTabs.Billing }), {
                isGlobal: true,
              });
            }}>
            {t('accounts.upgradePlan')}
          </Link>
        )}
      </Text.B2>
    </Alert>
  );
}
