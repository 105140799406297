import { gql } from '@apollo/client';
import { t } from 'i18next';
import { FetchAccountQuery, FetchAccountQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const FETCH_ACCOUNT = gql`
  query FetchAccount {
    fetchAccount {
      id
      displayName
      timezone
      subscriptionBillingAnchor
      subscriptionProrationBehavior
      accountEmailDomain
      samlEnabled
      defaultSSORoles {
        accountRole
        productionRole
        nonProductionRole
      }
    }
  }
`;

async function fetchAccount(_: void, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<FetchAccountQuery, FetchAccountQueryVariables>({
        query: FETCH_ACCOUNT,
        fetchPolicy: 'network-only',
      });

      return response.data.fetchAccount;
    },
    {
      failureMessageHandler: () => t('accounts.api.failFetchAcct'),
    },
    dispatch,
  );
}

export { fetchAccount };
